@if (uploadAction?.transition) {
    <nuc-button-bar>
        <nuc-button-secondary text="Cancel" [disabled]="!publicationItemsSubscription?.closed" (click)="clearUploadGroups()"></nuc-button-secondary>
        <nuc-button-secondary text="Browse files" [disabled]="!publicationItemsSubscription?.closed" (click)="fileInput()?.nativeElement?.click()"></nuc-button-secondary>
        <nuc-button-primary text="Save" [disabled]="saveButtonDisabled || !publicationItemsSubscription?.closed" (click)="onSaveButtonClicked()"></nuc-button-primary>
    </nuc-button-bar>

    @if (publicationItemsSubscription?.closed) {
        <div class="file-upload-container" (dragenter)="dragEnter()">
            @if (uploadGroups$ | async; as uploadGroups) {
                <div class="uploads">
                    @for (uploadGroup of uploadGroups; track uploadGroup) {
                        @if ((uploadGroup.validationStatus$ | async) === VALIDATION_STATUS.VALID) {
                            @switch (uploadGroup.viewType) {
                                @case (EUploadCategory.TEMPLATE) {
                                    <div class="upload-template-item">
                                        <div class="top-container">
                                            <ng-container *ngTemplateOutlet="matchedPublicationItem; context: uploadGroup"></ng-container>
                                            <nuc-button-secondary icon="nucicon_trash_fill"
                                                                  (click)="onDeleteButtonClicked(uploadGroup)"></nuc-button-secondary>
                                        </div>

                                        <div class="files">
                                            @for (fileTypeCategory of uploadGroup.requiredFileExtensions; track fileTypeCategory) {
                                                <div class="file" [class.inactive]="!getUploadForFileType(uploadGroup.uploads, fileTypeCategory)">
                                                    <img class="file-type-image" [src]="fileTypeCategory | fileTypeIcon"/>
                                                    <div class="file-info progress">
                                                        <p>{{fileTypeCategory | uppercase}}</p>
                                                        @if (getUploadForFileType(uploadGroup.uploads, fileTypeCategory); as upload) {
                                                            @if ({value: upload.progress$ | async}; as progress) {
                                                                @if (progress.value === EUploadStatus.Done) {
                                                                    <img class="status-icon" src="/assets/images/icon_success.svg"/>
                                                                }
                                                                @if (progress.value === EUploadStatus.Failed) {
                                                                    <img class="status-icon" src="/assets/images/icon_failed.svg"/>
                                                                }
                                                                @if (progress.value !== EUploadStatus.Done && progress.value !== EUploadStatus.Failed) {
                                                                    <nuc-progress-bar class="progress-bar" [value]="progress.value"></nuc-progress-bar>
                                                                }
                                                            } @else {
                                                                <p>Not uploaded</p>
                                                            }
                                                        } @else {
                                                            <p>Not uploaded</p>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                @case (EUploadCategory.FILE) {
                                    <div class="display-asset-item">
                                        @for (uploadFile of uploadGroup.uploads; track uploadFile) {
                                            <file-upload-card-view class="item"
                                                                   [itemWidth]="200"
                                                                   [uploadFile]="uploadFile"
                                                                   (deleteAssetClicked)="onDeleteButtonClicked(uploadGroup)">
                                                <div file-status>
                                                    <ng-container
                                                        *ngTemplateOutlet="matchedPublicationItem; context: uploadGroup"></ng-container>
                                                </div>
                                            </file-upload-card-view>
                                        }
                                    </div>
                                }
                            }
                        } @else {
                            <div class="display-asset-item">
                                @for (uploadFile of uploadGroup.uploads; track uploadFile) {
                                    <file-upload-card-view class="item"
                                                           [itemWidth]="200"
                                                           [uploadFile]="uploadFile"
                                                           (deleteAssetClicked)="onDeleteButtonClicked(uploadGroup)">
                                        <div file-status>
                                            <ng-container
                                                *ngTemplateOutlet="matchedPublicationItem; context: uploadGroup"></ng-container>
                                        </div>
                                    </file-upload-card-view>
                                }
                            </div>
                        }
                    }
                </div>
            }
            <div class="file-input-area"  [class.visible]="(uploadGroups$ | async)?.length < 1 || isDragging">
                <div class="input-group">
                    <input type="file"
                           #fileInput
                           name="file-[]"
                           id="fileInput"
                           class="input-file"
                           [class.isDragging]="isDragging"
                           multiple
                           (change)="onFilesSelected($event);"/>

                    <label class="head-label" for="fileInput">
                        <div class="drag-drop-row image">
                            <div class="drop-img" [class.isDragging]="isDragging"></div>
                        </div>
                        <div class="drag-drop-row margin-bot-base">
                            <p class="text-center">{{isDragging ? 'Now drop it' : 'Drag your files here'}}</p>
                        </div>
                        <div class="drag-drop-row">
                            <nuc-button-secondary (click)="fileInput.click()" text="Browse files"></nuc-button-secondary>
                        </div>
                    </label>
                </div>
            </div>
            @if (isDragging) {
                <div class="drag-detector"
                     (dragleave)="dragLeave()"
                     (drop)="drop($event)"
                     (dragover)="dragOver($event)"
                     #fileInput>
                </div>
            }
        </div>
    } @else {
        <rl-loading-indicator size="large"></rl-loading-indicator>
    }
} @else {
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission_small.svg"
                               [small]="true"
                               title="Your team does not have configured workflow transitions for uploading files. Contact the team owner to enable this section.">
    </nuc-empty-state-component>
}

<ng-template #matchedPublicationItem let-publicationItem$="publicationItem$" let-variant="variant"
             let-validationStatus$="validationStatus$" let-identifier="identifier">
    <div>
        <div class="title-container">
            @if (publicationItem$ | async; as publicationItem) {
                <p>{{(publicationItem | publicationItemDisplay:EPublicationDisplayProperties.UPLOAD_DISPLAY_NAME:undefined:publication.workflow.sequentialNumbering) || 'Item not found'}}</p>
                @if (variant) {
                    <p>/</p>
                    <p>{{variant.getTitle()}}</p>
                }
            }

            @if (validationStatus$ | async; as validationStatus) {
                @switch (validationStatus) {
                    @case (VALIDATION_STATUS.VALID) {
                        <i class="status-icon green nucicon_check_round_fill"></i>
                    }
                    @case (VALIDATION_STATUS.INVALID) {
                        <span class="text-ellipsis red">
                            No matching items
                            <i class="status-icon red nucicon_close_round_fill"></i>
                        </span>
                    }
                }
            }
        </div>

        @if (validationStatus$ | async; as validationStatus) {
            @if (validationStatus === VALIDATION_STATUS.VALID) {
                <p>{{identifier}}</p>
            }
        }
    </div>
</ng-template>
